import * as actionTypes from "./actionTypes";
import {
  START,
  DRAWING,
  POST_PROCESSING,
  FINAL_TOUCHES,
} from "../../constants/constants";

// error if unkown index used
export const setView = (view) => (dispatch, getState) => {
  switch (view) {
    case START:
      dispatch({ type: actionTypes.SET_VIEW, currentPage: START });
      break;
    case DRAWING:
      dispatch({ type: actionTypes.SET_VIEW, currentPage: DRAWING });
      break;
    case POST_PROCESSING:
      dispatch({
        type: actionTypes.SET_VIEW,
        currentPage: POST_PROCESSING,
      });
      break;
    case FINAL_TOUCHES:
      dispatch({
        type: actionTypes.SET_VIEW,
        currentPage: FINAL_TOUCHES,
      });
      break;
    default:
      console.error("Invalid index used");
      break;
  }
};

/**
 * opens sidebar
 * @returns undefined
 */
export const openSidebar = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.OPEN_SIDEBAR,
  });
};

/**
 * closes sidebar
 * @returns undefined
 */
 export const closeSidebar = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.CLOSE_SIDEBAR,
  });
};

/**
 * toggles sidebar
 * @returns undefined
 */
 export const toggleSidebar = () => (dispatch, getState) => {
  dispatch({
    type: actionTypes.TOGGLE_SIDEBAR,
  });
};

