import React from "react";
import { useDispatch } from "react-redux";
import * as Icon from "../../icons/icons";
import Select from "react-select";
import { toggleSidebar } from "../../store/actions/app";
import translations from "../../../translations.json";
import { useTranslation } from "react-i18next";
import "../../style/style.scss";
import { customStyles } from "../../style/selectStyle";

/**
 * everything happens here
 */
export const NavBar = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  // const isBrowser = () => typeof window !== "undefined"
  // let path = isBrowser ? window.location.pathname : "";

  const onClickMenu = () => {
    dispatch(toggleSidebar());
  };

  const translationOptions = [
    {
      value: translations.en.translation.lang,
      label: (
        <div className="pix-row-parent">
          <img
            className="row-element"
            alt={`${translations.en.translation.lang} flag`}
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              Icon.CountryIcons()[translations.en.translation.lang]
            )}`}
          ></img>
        </div>
      ),
    },
    {
      value: translations.fr.translation.lang,
      label: (
        <div className="pix-row-parent">
          <img
            className="row-element"
            alt={`${translations.fr.translation.lang} flag`}
            src={`data:image/svg+xml;utf8,${encodeURIComponent(
              Icon.CountryIcons()[translations.fr.translation.lang]
            )}`}
          ></img>
        </div>
      ),
    },
  ];

  return (
    <header className="pix-page-header pix-secondary-bkg">
      <div id="pix-mobile-navbar" className="d-md-none">
        <nav className="pix-navbar">
          <span className="pix-spacer-left-content">
            <button
              className="btn"
              style={{ width: "60px" }}
              onClick={() => onClickMenu()}
            >
              {Icon.getHamburgerMenu()}
            </button>
          </span>
          <a href={`/${i18n.language}`} className="">
            {Icon.getLogo("auto", "35")}
          </a>
          <span className="pix-spacer"></span>
        </nav>
      </div>
      <div id="pix-screen-navbar" className="d-none d-md-block">
        <nav className="navbar pix-navbar">
          <div className="container">
            <a href={`/${i18n.language}`} className="">
              {Icon.getLogo("auto", "35")}
            </a>
            <span className="pix-row-parent">
              <div className="row-element">
                <div className="pix-link-btn">
                  <a
                    className="nav-link"
                    href={`/${i18n.language}/about`}
                    style={{ color: "inherit", fontSize: "16px" }}
                  >
                    {t("content.navbar.howItWorks")}
                  </a>
                </div>
                <div className="pix-link-btn">
                  <a
                    className="nav-link"
                    href={`/${i18n.language}/contact`}
                    style={{ color: "inherit", fontSize: "16px" }}
                  >
                    {t("content.navbar.contactUs")}
                  </a>
                </div>
              </div>
              <div className="row-element">
                <Select
                  classNamePrefix="select"
                  options={translationOptions}
                  value={translationOptions.find(
                    (f) => f.value === i18n.language
                  )}
                  styles={customStyles}
                  onChange={({ value }) => i18n.changeLanguage(value)}
                ></Select>
              </div>
            </span>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default NavBar;
