import React from "react";

export const getLogo = (width, height) => (
  <svg
    width={width ? width : "154"}
    height={height ? height : "48"}
    viewBox="0 0 77 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        d="M16.164 2.673C19.2314 2.673 21.5677 3.34666 23.173 4.694C24.807 6.04133 25.624 8.005 25.624 10.585C25.624 13.1937 24.807 15.186 23.173 16.562C21.5677 17.938 19.2314 18.626 16.164 18.626H12.208V25.033H17.368V28H4.76905V25.033H8.76805V5.64H4.76905V2.673H16.164ZM16.121 15.616C20.0197 15.616 21.969 13.9533 21.969 10.628C21.969 7.30267 20.0197 5.64 16.121 5.64H12.251V15.616H16.121ZM32.6327 2.673H44.2427V5.64H40.2437L40.1577 25.033H44.2427V28H32.5897V25.033H36.7607V5.64H32.6327V2.673Z"
        fill="black"
      />
    </g>
    <rect x="52" y="3" width="25" height="25" fill="black" />
    <rect x="54" y="5" width="2" height="2" fill="white" />
    <rect x="54" y="5" width="2" height="2" fill="white" />
    <rect x="54" y="5" width="2" height="2" fill="white" />
    <rect x="54" y="5" width="2" height="2" fill="white" />
    <rect x="54" y="5" width="2" height="2" fill="white" />
    <rect x="54" y="5" width="2" height="2" fill="white" />
    <rect x="54" y="5" width="2" height="2" fill="white" />
    <rect x="54" y="5" width="3" height="3" fill="white" />
    <rect x="57" y="8" width="3" height="3" fill="white" />
    <rect x="60" y="11" width="3" height="3" fill="white" />
    <rect x="63" y="14" width="3" height="3" fill="white" />
    <rect x="60" y="17" width="3" height="3" fill="white" />
    <rect x="57" y="20" width="3" height="3" fill="white" />
    <rect x="54" y="23" width="3" height="3" fill="white" />
    <rect x="66" y="11" width="3" height="3" fill="white" />
    <rect x="69" y="8" width="3" height="3" fill="white" />
    <rect x="72" y="5" width="3" height="3" fill="white" />
    <rect x="66" y="17" width="3" height="3" fill="white" />
    <rect x="69" y="20" width="3" height="3" fill="white" />
    <rect x="72" y="23" width="3" height="3" fill="white" />
    <defs>
      <filter
        id="filter0_d"
        x="0.769047"
        y="0.672997"
        width="47.4737"
        height="33.327"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const getHamburgerMenu = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="100%"
    // height="100%"
    fill="currentColor"
    className="bi bi-list"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
    />
  </svg>
);
export const BackArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className="bi bi-arrow-left"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
    />
  </svg>
);

export const Hand = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="16"
    // height="16"
    fill="currentColor"
    className="bi bi-pencil"
    viewBox="0 0 16 16"
  >
    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
  </svg>
);

export const Upload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="16"
    // height="16"
    fill="currentColor"
    className="bi bi-upload"
    viewBox="0 0 16 16"
  >
    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
  </svg>
);

export const CountryIcons = () => {
  return {
    "en": "<svg xmlns='http://www.w3.org/2000/svg' id='flag-icon-css-gb' viewBox='0 0 640 480'><path fill='#012169' d='M0 0h640v480H0z'/><path fill='#FFF' d='M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z'/><path fill='#C8102E' d='M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z'/><path fill='#FFF' d='M241 0v480h160V0H241zM0 160v160h640V160H0z'/><path fill='#C8102E' d='M0 193v96h640v-96H0zM273 0v480h96V0h-96z'/></svg>",
    "fr": "<svg xmlns='http://www.w3.org/2000/svg' id='flag-icon-css-fr' viewBox='0 0 640 480'><g fill-rule='evenodd' stroke-width='1pt'>  <path fill='#fff' d='M0 0h640v480H0z'/>  <path fill='#00267f' d='M0 0h213.3v480H0z'/>  <path fill='#f31830' d='M426.7 0H640v480H426.7z'/></g></svg>"
  }
}