export const customStyles = {
    indicatorSeparator: () => {},
    dropdownIndicator: () => { },
    dropdownIndicator: () => ({
        marginRight: 5,
    }),
    control: styles => ({
        ...styles,
        border: 0,
        boxShadow: 'none',
        height: 30,
        minHeight: 30,
      }),
    container: (styles) => ({
        ...styles,
        width: 70,
        height: 30,
        borderColor: "red"
    }),
    options: () => ({
        height: 1,
        backgroundColor: "red"
    }),
    option: (styles) => ({
        ...styles,
    }),
  }
  